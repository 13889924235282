import Vue from 'vue';
import { HTTP, baseUploadURL, baseURL } from './axios';
import moment from 'moment';

Vue.prototype.$baseUploadURL = baseUploadURL;
Vue.prototype.$basePdfURL = baseURL + '/pdf/';
Vue.prototype.$baseZipURL = baseURL + '/zip/';
Vue.prototype.$http = HTTP;

Vue.prototype.$file2base64 = (
  ev: any,
  cb: (arg0: string | ArrayBuffer | null) => void,
) => {
  const file = ev.target.files[0];
  const reader = new FileReader();
  reader.onload = (e: ProgressEvent) => {
    const content = (e.target as FileReader).result;
    cb(content);
  };
  reader.readAsDataURL(file);
};

Vue.prototype.$errorHandler = (event: any) => {
  event.target.src = '/images/upload.png';
};

Vue.prototype.$checkRoles = (name: any) => {
  const user = JSON.parse(localStorage.admin);
  const roles = user.roles ?? JSON.parse(user.roles);
  if (user.id == 1) {
    return true;
  } else {
    if (roles.includes(name)) {
      return true;
    }
    return false;
  }
};

Vue.prototype.$errorHandlerPlayer = (event: any) => {
  event.target.src = '/images/default.png';
};

Vue.prototype.$errorHandlerCard = (event: any) => {
  event.target.src = '/images/card.png';
};

Vue.prototype.$errorHandlerCertificate = (event: any) => {
  event.target.src = '/images/certificates.png';
};

Vue.prototype.$eventHub = new Vue();

Vue.prototype.$TimeFormat = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('hh:mm A');
  } else {
    return '';
  }
};

Vue.prototype.$TimeFormatz = (value) => {
  if (value) {
    const duration = moment(String(value));

    return duration.format('hh:mm');
  } else {
    return '';
  }
};
