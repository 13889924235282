const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.user) {
    next();
    return;
  }
  next('/');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.user) {
    next();
    return;
  }
  next('/user');
};

export const user = [
  {
    path: '/',
    component: () => import('../views/user/index.vue'),
  },
  {
    path: '/home/:id',
    component: () => import('../views/user/home.vue'),
  },
  {
    path: 'league/:id',
    component: () => import('../views/user/league.vue'),
  },

  // years
  {
    path: 'addYears/:id',
    component: () => import('../views/user/years/index.vue'),
  },

  // coach
  // {
  //   path: 'coach/:id',
  //   component: () => import('../views/user/coach/index.vue'),
  // },
  // {
  //   path: 'coach-add/:id',
  //   component: () => import('../views/user/coach/add.vue'),
  // },

  {
    path: 'coach-edit/:id',
    component: () => import('../views/user/coach/edit.vue'),
  },

  // mindful

  // {
  //   path: 'mindful/:id',
  //   component: () => import('../views/user/mindful/index.vue'),
  // },
  // {
  //   path: 'mindful-add/:id',
  //   component: () => import('../views/user/mindful/add.vue'),
  // },

  {
    path: 'mindful-edit/:id',
    component: () => import('../views/user/mindful/edit.vue'),
  },

  // technical_manager
  // {
  //   path: 'technical_manager/:id',
  //   component: () => import('../views/user/technical_manager/index.vue'),
  // },
  // {
  //   path: 'technical_manager-add/:id',
  //   component: () => import('../views/user/technical_manager/add.vue'),
  // },

  {
    path: 'technical_manager-edit/:id',
    component: () => import('../views/user/technical_manager/edit.vue'),
  },

  // players
  // {
  //   path: 'players/:id',
  //   component: () => import('../views/user/players/index.vue'),
  // },
  // {
  //   path: 'players-add/:id',
  //   component: () => import('../views/user/players/add.vue'),
  // },

  {
    path: 'players-edit/:id',
    component: () => import('../views/user/players/edit.vue'),
  },
];
