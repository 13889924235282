const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.admin) {
    next();
    return;
  }
  next('/admin/login');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.admin) {
    next();
    return;
  }
  next('/admin');
};

export const admin = [
  {
    path: '/',
    component: () => import('../views/admin/layout.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/',
        component: () => import('../views/admin/index.vue'),
      },

      {
        path: 'logs',
        component: () => import('../views/admin/logs.vue'),
      },
      // admins
      {
        path: 'admins',
        component: () => import('../views/admin/admins/index.vue'),
      },
      {
        path: 'admins/add',
        component: () => import('../views/admin/admins/add.vue'),
      },
      {
        path: 'admins/edit/:id',
        component: () => import('../views/admin/admins/add.vue'),
      },
      {
        path: 'admins/roles/:id',
        component: () => import('../views/admin/admins/roles.vue'),
      },
      // academies
      {
        path: 'academies',
        component: () => import('../views/admin/academies/index.vue'),
      },
      {
        path: 'academies/add',
        component: () => import('../views/admin/academies/add.vue'),
      },
      {
        path: 'academies_print_page/:id',
        component: () => import('../views/admin/academies/print_page.vue'),
      },
      {
        path: 'academies/edit/:id',
        component: () => import('../views/admin/academies/add.vue'),
      },
      {
        path: 'academies/league/:id',
        component: () => import('../views/admin/academies/league.vue'),
      },
      {
        path: 'academies/league/years/:id/:academieId',
        component: () => import('../views/admin/academies/years.vue'),
      },
      // league
      {
        path: 'league',
        component: () => import('../views/admin/league/index.vue'),
      },
      {
        path: 'league/add',
        component: () => import('../views/admin/league/add.vue'),
      },
      {
        path: 'league/edit/:id',
        component: () => import('../views/admin/league/add.vue'),
      },

      // years
      {
        path: 'years',
        component: () => import('../views/admin/years/index.vue'),
      },
      {
        path: 'years/add',
        component: () => import('../views/admin/years/add.vue'),
      },
      {
        path: 'years/edit/:id',
        component: () => import('../views/admin/years/add.vue'),
      },

      // coach
      {
        path: 'coach',
        component: () => import('../views/admin/coach/index.vue'),
      },
      {
        path: 'coach-edit/:id',
        component: () => import('../views/admin/coach/edit.vue'),
      },
      // mindful
      {
        path: 'mindful',
        component: () => import('../views/admin/mindful/index.vue'),
      },

      {
        path: 'mindful-edit/:id',
        component: () => import('../views/admin/mindful/edit.vue'),
      },
      // players
      {
        path: 'players',
        component: () => import('../views/admin/players/index.vue'),
      },
      {
        path: 'players-move',
        component: () => import('../views/admin/players/move.vue'),
      },

      {
        path: 'players-edit/:id',
        component: () => import('../views/admin/players/edit.vue'),
      },

      {
        path: 'players-print/:id',
        component: () => import('../views/admin/players/print.vue'),
      },

      // technical_manager
      {
        path: 'technical_manager',
        component: () => import('../views/admin/technical_manager/index.vue'),
      },
      {
        path: 'technical_manager-edit/:id',
        component: () => import('../views/admin/technical_manager/edit.vue'),
      },

      // carnics
      {
        path: 'carnics',
        component: () => import('../views/admin/carnics/index.vue'),
      },

      {
        path: 'carnics/add',
        component: () => import('../views/admin/carnics/add.vue'),
      },
      {
        path: 'carnics/edit/:id',
        component: () => import('../views/admin/carnics/add.vue'),
      },
      {
        path: 'carnics/print/:id/:leaguesId',
        component: () => import('../views/admin/carnics/print.vue'),
      },

      {
        path: 'carnics/printAll/:id/:leaguesId',
        component: () => import('../views/admin/carnics/print_all.vue'),
      },

      // certificates
      {
        path: 'certificates',
        component: () => import('../views/admin/certificates/index.vue'),
      },

      {
        path: 'certificates/add',
        component: () => import('../views/admin/certificates/add.vue'),
      },
      {
        path: 'certificates/edit/:id',
        component: () => import('../views/admin/certificates/add.vue'),
      },
      {
        path: 'certificates/print/:id',
        component: () => import('../views/admin/certificates/print.vue'),
      },

      // ascorchit
      {
        path: 'ascorchit',
        component: () => import('../views/admin/ascorchit/index.vue'),
      },
      {
        path: 'ascorchit/add',
        component: () => import('../views/admin/ascorchit/add.vue'),
      },

      // championship
      {
        path: 'championship/:id',
        component: () => import('../views/admin/championship/index.vue'),
      },
      // {
      //   path: 'championship/add',
      //   component: () => import('../views/admin/championship/add.vue'),
      // },
      {
        path: 'championship_edit/:id',
        component: () => import('../views/admin/championship/add.vue'),
      },

      // score
      {
        path: 'score',
        component: () => import('../views/admin/score/index.vue'),
      },

      // news
      {
        path: 'news',
        component: () => import('../views/admin/news/index.vue'),
      },
      {
        path: 'news/add',
        component: () => import('../views/admin/news/add.vue'),
      },
      {
        path: 'news/edit/:id',
        component: () => import('../views/admin/news/add.vue'),
      },
      // organizers
      {
        path: 'organizers',
        component: () => import('../views/admin/organizers/index.vue'),
      },
      {
        path: 'organizers/add',
        component: () => import('../views/admin/organizers/add.vue'),
      },
      {
        path: 'organizers/edit/:id',
        component: () => import('../views/admin/organizers/add.vue'),
      },
    ],
  },
  {
    path: 'championshipPrint/:id',
    component: () => import('../views/admin/championship/print.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: 'login',
    component: () => import('../views/admin/login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
];
