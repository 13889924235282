const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.admin) {
    next();
    return;
  }
  next('/admin/login');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.admin) {
    next();
    return;
  }
  next('/admin');
};

export const soft = [
  {
    path: '/',
    component: () => import('../views/soft/layout.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/',
        component: () => import('../views/soft/index.vue'),
      },

      // admins
      {
        path: 'admins',
        component: () => import('../views/soft/admins/index.vue'),
      },

      // academies
      {
        path: 'academies',
        component: () => import('../views/soft/academies/index.vue'),
      },

      // league
      {
        path: 'league',
        component: () => import('../views/soft/league/index.vue'),
      },

      // years
      {
        path: 'years',
        component: () => import('../views/soft/years/index.vue'),
      },

      // coach
      {
        path: 'coach',
        component: () => import('../views/soft/coach/index.vue'),
      },

      // mindful
      {
        path: 'mindful',
        component: () => import('../views/soft/mindful/index.vue'),
      },

      // players
      {
        path: 'players',
        component: () => import('../views/soft/players/index.vue'),
      },

      // technical_manager
      {
        path: 'technical_manager',
        component: () => import('../views/soft/technical_manager/index.vue'),
      },

      // carnics
      {
        path: 'carnics',
        component: () => import('../views/soft/carnics/index.vue'),
      },

      // certificates
      {
        path: 'certificates',
        component: () => import('../views/soft/certificates/index.vue'),
      },

      // ascorchit
      {
        path: 'ascorchit',
        component: () => import('../views/soft/ascorchit/index.vue'),
      },

      // championship
      {
        path: 'championship',
        component: () => import('../views/soft/championship/index.vue'),
      },

      // score
      {
        path: 'score',
        component: () => import('../views/soft/score/index.vue'),
      },

      // news
      {
        path: 'news',
        component: () => import('../views/soft/news/index.vue'),
      },
    ],
  },
];
