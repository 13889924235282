import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import { admin } from './admin';
import { soft } from './soft';
import { user } from './user';

Vue.use(VueRouter);
const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.user) {
    next();
    return;
  }
  next('/');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.user) {
    next();
    return;
  }
  next('/user');
};

const routes: RouteConfig[] = [
  {
    path: '/',
    component: Home,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/admin',
    component: () => import('../views/admin/admin.vue'),
    children: admin,
  },

  {
    path: '/soft',
    component: () => import('../views/soft/admin.vue'),
    children: soft,
  },
  {
    path: '/user',
    component: () => import('../views/user/layout.vue'),
    children: user,
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
